@charset "UTF-8";
.message-box {
  box-shadow: 0 0 5px lightgray;
  padding: 10px;
  margin: 6px;
  border-radius: var(--border-radius-sm);
}

.text-lg {
  color: var(--gray-400);
}

.row-card {
  border-radius: 6px;
  box-shadow: 0 0 10px lightgray;
  margin: -10px;
  margin-top: 20px;
  flex-grow: 1;
  transition: width 0.3s ease;
  padding: 8px;
}

.row-card .money {
  text-align: center;
  font-size: xx-large;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.bg-aliceblue {
  background-color: aliceblue;
}

.bg-floralwhite {
  background-color: floralwhite;
}

.table th {
  background-color: #eeeeee;
}

.doc-field {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); /* 默认两列 */
  grid-gap: 10px;
}

@media (max-width: 575px) {
  .doc-field {
    grid-template-columns: 1fr; /* 在小屏幕上改为一列 */
  }
}
.heatmap {
  display: flex;
  justify-content: center;
}
.heatmap .chart-container {
  margin: 0px;
}
.heatmap .chart-legend {
  display: none;
}